import React from 'react';
// import { navigate, Link } from 'gatsby';
import Title from '../../components/Title';
import Layout from '../../components/Layout';
import Image from '../../components/Image';
import Seo from '../../components/Seo';
import './style.sass';

export default props => {
  return (
    <Layout>
      <Seo
        title="マダムたぬきとは"
        description="マダムたぬきについてのページです"
        href={props.location.href}
      />
      <div className="about">
        <Title>マダムたぬきとは</Title>
        <figure className="about__figure">
          <Image filename="img_normal.png" />
        </figure>
        <div className="about__message">
          ちょっといい加減でびっくりするくらい当たる、マダムたぬきの夢占い。
          クリエイター集団「pocopons」は、夢の持つ意味や古来からの言い伝えを勉強し、心理学を織り交ぜてオリジナル夢占いを作成。
          マダムたぬきの夢占いとして、2020年秋からサイトをオープン。
          怖い夢を見たとき、寝起きにもやもやしたとき。
          不可思議な夢の意味を知り、現代を生きる小さなヒントにしてもらえれば。
        </div>
      </div>
    </Layout>
  );
};
